/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import EditUserList from "../../Api/EditUserList";
import EditCategory from "../../Api/EditCategory";
import AddCategory from "../../Api/AddCategory";
import DeleteCategory from "../../Api/DeleteCategory";
import EditCharityList from "../../Api/EditCharityList";
import DeleteCharity from "../../Api/DeleteCharity";
import DeleteFundraise from "../../Api/DeleteFundraise";
import DeleteFundraiseApplication from "../../Api/DeleteFundraiseApplication";
import EditFundraise from "../../Api/EditFundraise";
import EditBlogCategory from "../../Api/EditBlogCategory";
import DeleteBlogCategory from "../../Api/DeleteBlogCategory";
import AddBlogCategory from "../../Api/AddBlogCategory";
import EditBlogAPI from "../../Api/EditBlogAPI";
import DeleteBlog from "../../Api/DeleteBlog";
import EditContactList from "../../Api/EditContactList";
import DeleteFundraiseUpdatesAPI from "../../Api/DeleteFundraiseUpdatesAPI";
import DeleteContactList from "../../Api/DeleteContactList";
import EditFundraiseApplications from "../../Api/EditFundraiseApplications";
import DeleteAthlete from "../../Api/DeleteAthlete";
import EditAthleteList from "../../Api/EditAthleteList";
import DeleteCampaign from "../../Api/DeleteCampaign";
import EditCampaign from "../../Api/EditCampaign";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editUserLists } from "../../redux/actions/usersActions";
import { editCategoryLists, addCategoryList, deleteCategoryLists } from "../../redux/actions/categoryActions";
import { editCharityListFnct, deleteCharityFnct } from "../../redux/actions/charityActions";
import { deleteFundraiseList, editFundraiseList, deleteFundraiseUpdatesList } from "../../redux/actions/fundActions";
import { editBlogCategoryLists, deleteBlogCategoryLists, addBlogCategoryList } from "../../redux/actions/blogCategoryActions";
import { editBlogLists, deleteBlogLists } from "../../redux/actions/blogActions";
import { editContactListAction, deleteContactListAction } from "../../redux/actions/contactusActions";
import { editFundraiseApplication, deleteFundraiseApplicationRdx } from "../../redux/actions/fundraiseApllicationActions";
import { deleteAthleteFnct, editAthleteListFnct } from "../../redux/actions/athleteAction";
import { DeleteCampaignList, UpdateCampaignX, setStoreUpdateBlogDetails } from "../../redux/actions/campaignAction";
import { json, useNavigate } from "react-router-dom";
import { BLOG_DELETE_API_URL, BLOG_UPDATE_API_URL, DELETE_COUPON_API_URL, UPDATE_COUPON_API_URL, UPDATE_SETTING_MODULE, USER_MANAGEMENT_URL } from "../Shared/constant";
import CheckValid from "./CheckValid";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CommonLogout from "./CommonLogout";
import DeletePAckage from "../../Api/DeletePackage";
import { DeletePackageList, setDeleteCouponList, setUpdateCouponList, UpdatePackageX } from "../../redux/actions/packageAction";
import EditPackage from "../../Api/Editpackage";
import { PostRequestAPI } from "../../Api/PostRequest";
import { GetRequestCallAPI } from "../../Api/GetRequest";
import { AuthenticateResponse } from "../Shared/utils";
import { UpdateDonationList } from "../../redux/actions/donationAction";
import { EditDonation } from "../../Api/EditDonation";


const CommonModal = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { showModal, setShowModal } = props;
    const { accessToken } = useSelector((state) => state.personalReducers);
    const { sponsorsData } = useSelector((state) => state.campaignReducers);
    const [ categoryName, setCategoryName ] = useState(showModal?.Data?.name === null ? "" : showModal?.Data?.name);
    // error field 
    const [ errorCategoryname, setErrorCategoryname ] = useState("");

    // close modal
    const handleClose = () => {
        setShowModal({...showModal, open: false, Data: {}});
        setCategoryName("");
    };

    // edit user function
    const EditData = async () => {
        if(showModal?.category === "User Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                user_id: showModal?.Data.id
            });
            const response = await EditUserList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editUserLists(response.data));
                    handleClose();
                    Navigate(USER_MANAGEMENT_URL);
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } if(showModal?.category === "Blog update") {
            const jsonData = {
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                blog_id: showModal?.Data.id,
            }
            const response = await PostRequestAPI(BLOG_UPDATE_API_URL, jsonData, accessToken);
            console.log("response", response)
            if(response?.status === 200){
                    toast.success(response.msg);
                    // dispatch(setStoreUpdateBlogDetails(response.data?.data));
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                    handleClose();
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        }  if(showModal?.category === "Charity Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data.slug
            });
            const response = await EditCharityList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editCharityListFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        }  if(showModal?.category === "Campaign Active") {
            // console.log("showModal?.Data?",showModal?.Data)
            // {
            //     "added_by": "06095997-8f68-4c08-b925-2b71b5a7cd5c",
            //     "title": "New One Comapign",
            //     "subtitle": "New One Comapign subtitle",
            //     "description": "%3Cp%3Ekdniw%20kwjnqif%3C/p%3E",
            //     "athlete": "{'image': 'https://nwchat.s3.amazonaws.com/media/thumbnails/400/9cf573e5-b63b-41f5-b84e-e44abe44b475.jpg', 'title': 'new Athlete  title', 'subtitle': 'new Athlete subtitle', 'youtubevideo': '', 'description': '<p>kefihw wje fibq</p>', 'bio': '', 'avatar': None}",
            //     "charity": "{'image': 'https://nwchat.s3.amazonaws.com/media/thumbnails/400/4e6e692d-4db7-48a0-8402-1d03fd4fe585.png', 'title': 'New Charity title', 'subtitle': 'New Charity subtitle', 'youtubevideo': '', 'description': '<p>skfiwh kwfiwfwf</p>', 'bio': '', 'avatar': None}",
            //     "is_active": false,
            //     "sequence_no": null,
            //     "slug": "new-one-comapign",
            //     "deleted_at": null,
            //     "id": "4d6a214a-3bc6-41a1-ae8c-941c77c6e141",
            //     "created_at": "2024-05-25 11:13:08.082961+00:00",
            //     "updated_at": "2024-05-25 12:03:57.301876+00:00"
            // }
            const CureentAthleteValue = JSON.parse(showModal?.Data.athlete?.replace("None", 'null')?.replaceAll(/'/g, '"'));
            const CureentCharityValue = JSON.parse(showModal?.Data.charity?.replace("None", 'null')?.replaceAll(/'/g, '"'));
            // console.log("CureentCharityValue",CureentCharityValue)
            const jsonData = JSON.stringify({
                slug: showModal?.Data?.slug,
                title: showModal?.Data?.title,
                subtitle: showModal?.Data?.subtitle,
                description: showModal?.Data?.description,
                is_active: showModal?.Data?.is_active === false ? 1 : 0,
                sequence_no:showModal?.Data?.sequence_no,
                web_appearance:showModal?.Data?.web_appearance ? "1" : "0",
                campaign_url:showModal?.Data?.campaign_url,

                athlete:  {
                    image: CureentAthleteValue?.image,
                    title: CureentAthleteValue?.title,
                    subtitle: CureentAthleteValue?.subtitle,
                    youtubevideo: CureentAthleteValue?.youtubevideo,
                    description: CureentAthleteValue?.description?.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: CureentAthleteValue?.bio?.replaceAll('"', '&#34').replaceAll("'", '&#39')
                },
                charity:  {
                    image: CureentCharityValue?.image,
                    title: CureentCharityValue?.title,
                    subtitle: CureentCharityValue?.subtitle,
                    youtubevideo: CureentCharityValue?.youtubevideo,
                    description: CureentCharityValue?.description?.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    bio: CureentCharityValue?.bio?.replaceAll('"', '&#34').replaceAll("'", '&#39'),
                    Logo:CureentCharityValue?.Logo,
                    MinimumAmount:CureentCharityValue?.MinimumAmount,
                    DefaultAmount:CureentCharityValue?.DefaultAmount,
                    ContributeTitle:CureentCharityValue?.ContributeTitle,
                    YouTubeLinks:CureentCharityValue?.YouTubeLinks,
                    button_title:CureentCharityValue?.button_title,
                    CommunityTitle:CureentCharityValue?.CommunityTitle,
                    campaign_video:CureentCharityValue?.campaign_video,
                    SponsorsTitle:CureentCharityValue?.SponsorsTitle,
                    SponsorsLogo:CureentCharityValue?.SponsorsLogo,
                    SocialLogo:CureentCharityValue?.SocialLogo,
                    CommunityDescription:CureentCharityValue?.CommunityDescription,
                    CommunityImage:CureentCharityValue?.CommunityImage,
                    CommunityLogo:CureentCharityValue?.CommunityLogo,
                    CommunitySocial:{
                        facebook:CureentCharityValue?.CommunitySocial?.facebook ? CureentCharityValue?.CommunitySocial?.facebook : "",
                        instagram:CureentCharityValue?.CommunitySocial?.instagram ? CureentCharityValue?.CommunitySocial?.instagram : "",
                        youtube:CureentCharityValue?.CommunitySocial?.youtube ? CureentCharityValue?.CommunitySocial?.youtube : "",
                        twitter:CureentCharityValue?.CommunitySocial?.twitter ? CureentCharityValue?.CommunitySocial?.twitter : "",
                        mail:CureentCharityValue?.CommunitySocial?.mail ? CureentCharityValue?.CommunitySocial?.mail : "",
                    }


                },
            });

            const response = await EditCampaign(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(UpdateCampaignX(response.data));
                    // console.log("response.data",response.data)
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Add category") {
            if(categoryName !== "" && categoryName !== undefined) {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                });
                const response = await AddCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addCategoryList(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }      
        } else if(showModal?.category === "Add Blog Category") {
            if(categoryName !== "" && categoryName !== undefined) {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                });
                const response = await AddBlogCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(addBlogCategoryList(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Edit category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                    slug: showModal?.Data?.slug
                });
                const response = await EditCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editCategoryLists(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Edit Blog Category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({
                    name: categoryName, 
                    slug: showModal?.Data?.slug
                });
                const response = await EditBlogCategory(accessToken, jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        dispatch(editBlogCategoryLists(response.data));
                        handleClose();
                    }, 100);
                } else if(response?.detail === "Token expired.") {
                    CommonLogout();
                    toast.error(response?.error || response.data.error);
                } else {
                    toast.error(response?.error || response.data.error);
                };
            } else {
                CheckValid(categoryName, {type: 'categoryname', error: setErrorCategoryname });
            }
        } else if(showModal?.category === "Athlete Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditCharityList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editAthleteListFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Category active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditCategory(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraiseApplications(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseApplication(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application verified") {
            const jsonData = JSON.stringify({
                is_verified: showModal?.Data.is_verified === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraiseApplications(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseApplication(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Blog Category active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditBlogCategory(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editBlogCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser comment") {
            const jsonData = JSON.stringify({
                is_comments: showModal?.Data.is_comments === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Blog Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditBlogAPI(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editBlogLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Contact Active") {
            const jsonData = JSON.stringify({
                is_active: showModal?.Data.is_active === false ? '1' : '0' , 
                contact: showModal?.Data?.slug
            });
            const response = await EditContactList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Contacted Us") {
            const jsonData = JSON.stringify({
                is_contacted: showModal?.Data.is_contacted === false ? '1' : '0' , 
                contact: showModal?.Data?.slug
            });
            const response = await EditContactList(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraiser donations") {
            const jsonData = JSON.stringify({
                accept_donations: showModal?.Data.accept_donations === false ? 1 : 0 , 
                slug: showModal?.Data?.slug
            });
            const response = await EditFundraise(accessToken, jsonData);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(editFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete contact") {
            const response = await DeleteContactList(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteContactListAction(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete category") {
            const response = await DeleteCategory(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "fundraise application delete") {
            const response = await DeleteFundraiseApplication(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseApplicationRdx(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog category") {
            const response = await DeleteBlogCategory(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteBlogCategoryLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Charity") {
            const response = await DeleteCharity(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteCharityFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Athlete") {
            const response = await DeleteCharity(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteAthleteFnct(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Fundraise") {
            const response = await DeleteFundraise(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog") {
            const response = await DeleteBlog(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteBlogLists(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Fundraise Updates") {
            const response = await DeleteFundraiseUpdatesAPI(accessToken, showModal?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(deleteFundraiseUpdatesList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Blog Data") {
            //     const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            const response = await GetRequestCallAPI(`${BLOG_DELETE_API_URL}/${showModal?.Data?.slug}`, accessToken);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success("Blog deleted successfully");
                    handleClose();
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Sponsors") {
            console.log("showModal?.Data", showModal?.Data)
            const oldSponsors = sponsorsData?.facefunding_sponsors?.filter(item1=>item1?.id !== showModal?.Data?.id)
            //     const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            const response = await PostRequestAPI(UPDATE_SETTING_MODULE, {data:{facefunding_sponsors: oldSponsors}}, accessToken);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success("Sponsor deleted successfully");
                    // dispatch(DeleteCampaignList(response.data));
                    handleClose();
                    if(props?.onCloseModal){
                        props?.onCloseModal();
                    }
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Campaign") {
            const response = await DeleteCampaign(accessToken, showModal?.Data?.slug);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(DeleteCampaignList(response.data));
                    handleClose();
                }, 100);
            } else if(response?.detail === "Token expired.") {
                CommonLogout();
                toast.error(response?.error || response.data.error);
            } else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Package") {
            const response = await DeletePAckage(accessToken, showModal?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    toast.success(response.msg);
                    dispatch(DeletePackageList(response.data));
                    handleClose();
                }, 100);
            }else {
                toast.error(response?.error || response.data.error);
            };
        } else if(showModal?.category === "Delete Coupon") {
            const response = await GetRequestCallAPI(`${DELETE_COUPON_API_URL}/${showModal?.Data?.id}`, accessToken);
            console.log("response", response)
            if(response?.status === 200){
                toast.success(response?.data?.msg);
                dispatch(setDeleteCouponList(response?.data?.data));
                handleClose();
            }else {
                AuthenticateResponse(response);
            };
        } else if(showModal?.category ==="Coupon Active"){
            const jsonData = {
               coupon_id: showModal?.Data?.id,
               name: showModal?.Data?.name,
               quantity: showModal?.Data?.quantity,
               code: showModal?.Data?.code,
               is_active: showModal?.Data?.is_active === false ? 1 : 0,
             }
             const response = await PostRequestAPI(`${UPDATE_COUPON_API_URL}`,  jsonData, accessToken);
             console.log("response", response)
             if(response?.status === 200){
                toast.success(response?.data?.msg);
                dispatch(setUpdateCouponList(response?.data?.data));
                handleClose();
            }else {
                AuthenticateResponse(response);
            };
            
        } else if(showModal?.category ==="Package Active"){
            const jsonData = JSON.stringify({
               name:showModal?.Data?.name,
               quantity:showModal?.Data?.quantity,
               is_active: showModal?.Data?.is_active === false ? 1 : 0,
               package_id:showModal?.Data?.id
             })
             const response = await EditPackage(accessToken, jsonData);
             if(response?.status === 200){
                 setTimeout(() => {
                     toast.success(response.msg);
                     dispatch(UpdatePackageX(response.data));
                     handleClose();
                 }, 100);
             } else if(response?.detail === "Token expired.") {
                 CommonLogout();
                 toast.error(response?.error || response.data.error);
             } else {
                 toast.error(response?.error || response.data.error);
             };
        }else if(showModal?.category ==="Donation Active"){
            const jsonData = JSON.stringify({
               is_active: showModal?.Data?.is_active === false ? "1" : "0",
               id:showModal?.Data?.id
             })
             const response = await EditDonation(accessToken, jsonData);
             if(response?.status === 200){
                 setTimeout(() => {
                     toast.success(response.msg);
                     dispatch(UpdateDonationList(response.data));
                     handleClose();
                 }, 100);
             } else if(response?.detail === "Token expired.") {
                 CommonLogout();
                 toast.error(response?.error || response.data.error);
             } else {
                 toast.error(response?.error || response.data.error);
             };
        }
    };

    return(<Modal className="commonModal" show={showModal?.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ width: showModal?.title === "Are you sure?" ? "100%" : ""}}>{showModal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showModal?.modalType === "form" ? (<div className="formloginset">
                <Form.Group className="mb-3" >
                    <Form.Label>Enter Category Name</Form.Label>
                    <Form.Control type="text" value={categoryName} onKeyUp={(e) => CheckValid(e.target.value, {type: 'categoryname', error: setErrorCategoryname })} onChange={(e) => setCategoryName(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter First Name" />
                    {errorCategoryname && <div className="danger-colorset">{errorCategoryname}</div>}
                </Form.Group>
            </div>) : (<>
                <div className="notformsection">
                    <p>{showModal?.description}</p>
                    {showModal?.category === "Delete Charity" ? (<h5>{showModal?.Data?.name}</h5>) : 
                    (showModal?.category === "Delete Fundraise" || showModal?.category === "Delete Campaign" || showModal?.category === "Delete Blog Data" || showModal?.category === "Delete Sponsors" || showModal?.category === "Delete Blog" || showModal?.category === "fundraiser active" || showModal?.category === "fundraiser comment" || showModal?.category === "fundraiser donations" || showModal?.category === "Blog Active" || showModal?.category === "Blog update") ? 
                    (<h5>{showModal?.Data?.title}</h5>) : (showModal?.category === "Contact Active" || showModal?.category === "Contacted Us") ? (<h5>{showModal?.Data?.from_email}</h5>) : 
                    (showModal?.category === "Campaign Active") ? (<h5>{showModal?.Data?.title}</h5>) :(<h5>{showModal?.Data?.email === undefined ? showModal?.Data?.name : showModal?.Data?.email}</h5>)}
                </div>
            </>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => EditData()}>
            {showModal?.button}
          </Button>
        </Modal.Footer>
    </Modal>);
}

export default CommonModal;